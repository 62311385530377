import styled from "@emotion/styled";
import Grid from "@mui/material/Grid2";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import Loader from "../components/Loader";
import useAuth from "../hooks/useAuth";
import LandingLayout from "../layouts/Landing";
import Head from "../components/Head";

const Wrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function Index() {
  const { isAuthenticated, isInitialized } = useAuth();
  const router = useRouter();

  // We want to wait until both the router is ready and the auth is initialized
  useEffect(() => {
    // If the router is ready and authentication is initialized, proceed with the redirect logic
    if (router.isReady && isInitialized !== undefined) {
      console.log("Checking initialization and authentication");
      if (isInitialized && isAuthenticated) {
        console.log("User is authenticated and initialized, redirecting to dashboard.");
        router.push("/dashboard");
      } else if (isInitialized && !isAuthenticated) {
        console.log("User is initialized but not authenticated, redirecting to sign-in.");
        router.push("/auth/sign-in");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isInitialized, isAuthenticated]);

  return (
    <Wrapper container spacing={0}>
      <Head title="Majors" />
      <Loader />
    </Wrapper>
  );
}

Index.getLayout = function getLayout(page) {
  return <LandingLayout>{page}</LandingLayout>;
};

export default Index;
