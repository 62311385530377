import NextHead from "next/head";

export default function Head({ title = "Finance" }) {
  return (
    <>
      <NextHead>
        <title>{`Majors | ${title}`}</title>
      </NextHead>
    </>
  );
}
