import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React from "react";

const Root = styled("div")`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  height: 100%;
  padding: 32px 0;
`;

function Loader() {
  return (
    <Root>
      <CircularProgress color="primary" />
    </Root>
  );
}

export default Loader;
