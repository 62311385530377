import React from "react";
import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";

const Root = styled("div")`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const LandingLayout = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default LandingLayout;
